export const useFilters = (
    filters,
    /*
    [
        {
            name: filter_project
            needsSelect2: true/false
            column: 3
            filterFunc?: (element, table) => {}  (if this is defined, column isn't used)
            placeholder: Project
        }
    ]
     */
    table,
) => {

    $("#toggle_filter").click(function() {
        if ($("#filter_div").css("display") == "none") {
            $("#filter_div").css("display", "flex");
        } else {
            $("#filter_div").css("display", "none");
        }
    });

    $("#toggle_filters").click(function() {
        $("#filter_div").toggleClass("display-none");
        $("#filter_div").toggleClass("flex-row");
        $("#filter_icon").toggleClass("fa-caret-square-down");
        $("#filter_icon").toggleClass("fa-caret-square-up");
    });

    filters.forEach(filter => {
        // init select 2 if necessary
        if (filter["needsSelect2"]) {
            $(`#${filter["name"]}`).select2({
                placeholder: filter["placeholder"],
                width: "100%",
                allowClear: true,
                containerCssClass: "filterselect"
            });
        }

        // init event listeners to perfom searches
        $(`#${filter["name"]}`).on("keyup change", function () {
            if (filter["filterFunc"]) {
                return filter["filterFunc"]($(this), table);
            }
            table.column(
                filter["column"]
            ).search(
                $(this).val()
            ).draw();
        });
    });

};

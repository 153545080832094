/**
 * Allow table cells to be edited and saved by clicking inside and outside of them
 *
 * @param tableName
 * @param isAdminOrRoot
 * @param dropdownRoutes expects an object with the folling properties: name
 * (should equal data-dropdown of the td), route (url for ajax), value (should equal json
 * property of response that shall be applied) and either html (if json property is to be shown)
 * or generateHtml (if html has to be generated from the property)
 * @param ajaxSaveRoute
 * @param csrfToken
 */
export const useHandleTableClick = (
    tableName,
    isAdminOrRoot,
    dropdownRoutes,
    /*
    * dropdownRoutes: [
    *   {
    *       name: project
    *       route: ajax/projects
    *       value: project_id
    *       html: project_name
    *       generateHtml?: (td, json) => {}  // overrides html if
    *                                             complicated html has to be generated
    *   }
    * ]
    */
    ajaxSaveRoute,
    csrfToken,

) => {

    $(`#${tableName}`).on("click", "td", function (event) {
        if (isAdminOrRoot) {
            if ($(event.target).hasClass("open-link")) return;
            setTimeout(handle_td_click, 0, this);
        }
    });

    $(document).click(function(e) {
        let target = $(e.target);

        if (target.hasClass("table_input") || target.parents(".table_input").length) {
            return;  // active input was clicked
        }
        if (target.hasClass("table_select") || target.parents(".table_select").length) {
            return;  // active select was clicked
        }

        // if active input or select exists
        if ($(".table_input,.table_select").length) {
            // save row and make it inactive
            save_active_row();
        }
    });

    const handle_td_click = (
        clickedElement
    ) => {
        let td = $(clickedElement);
        if (td.data("active")) return;
        let classname = td.data("class");
        if (!classname) return;
        if (classname === "dropdown") {
            let options = [];
            dropdownRoutes.forEach(element => {
                if (td.data("dropdown") === element["name"]) {
                    $.ajax({
                        method: "GET",
                        url: element["route"],
                        async: false,
                        success: function (data) {
                            let json = $.parseJSON(data);
                            json.forEach(function (item) {
                                options.push({"id": item.id, "name": item.name});
                            });
                        },
                        error: function (data) {
                            console.error(data.responseText);
                            return td;
                        }
                    });
                }
            });
            let html = "<div class='table_select'><select class=''>";
            options.forEach(item => {
                html += `<option value='${item.id}'>${item.name}</option>`;
            });
            html += "</select></div>";
            td.html(html);
        } else if (classname === "url") {
            let v = td.data("value");
            if (v === null) v = "";
            let html = `<input class="table_input" type="url" value="${v}">`;
            td.html(html);
        } else if (classname === "month") {
            let html = `<input class="table_input month-picker" type="url" value="${td.html()}">`;
            td.html(html);
            $(".month-picker").datepicker({
                format: "mm/yyyy",
                autoHide: true,
            });
        } else {
            let html = `<input class="table_input" type="${classname}" value="${td.html()}">`;
            td.html(html);
        }
        td.data("active", true);
        return td;
    };

    const save_active_row = (

    ) => {
        let row = $(".table_input,.table_select").parents("tr");
        let values = { "_method": "PATCH", "_csrf": csrfToken };
        row.children("td").each(function() {
            let el = $(this);
            if (el.data("column")) {
                if (el.data("dropdown")) {
                    if (el.children(".table_select").length) {
                        values[el.data("column")] = el.children(".table_select").children().val();
                    } else {
                        values[el.data("column")] = el.data("value");
                    }
                } else {
                    if (el.children(".table_input").length) {
                        values[el.data("column")] = el.children(".table_input").val();
                    } else {
                        values[el.data("column")] = el.text();
                    }
                }
            }
        });
        $.ajax({
            method: "POST",
            url: ajaxSaveRoute + row.attr("id"),
            async: false,
            data: values,
            success: function(data) {
                console.log(data);
                let json = $.parseJSON(data);
                if ($(".table_select").length) {
                    // is dropdown
                    let td = $(".table_select").parents("td");

                    const lookup = dropdownRoutes.find(el => {
                        if (el["name"] === td.data("column")) return true;
                        return false;
                    });

                    td.data("value", json[lookup["value"]]);
                    if (lookup["generateHtml"]) {
                        lookup["generateHtml"](
                            td,
                            json
                        );
                    } else {
                        td.html(json[lookup["html"]]);
                    }

                    td.data("active", false);
                }
                if($(".table_input").length) {
                    let td = $(".table_input").parents("td");
                    td.html(json[td.data("column")]);
                    td.data("active", false);
                }
            },
            error: function(data) {
                console.error(data.responseJSON);
                //TODO error handling, show errors or something
            }
        });
    };

};

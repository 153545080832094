/**
 * init an ckeditor5 instance
 *
 * returns the editor object
 * @param editorId id of the textarea that shall be converted
 * @param isAdmin if false, show a readonly editor
 * @param callback expects (editorObject) => {}
 * @param canWordCount set true to enable word counting
 * @param onWordCountUpdate expects (event, stats) => {}
 */
export const useEditor = (
    editorId,
    isAdmin,
    callback,
    canWordCount = false,
    onWordCountUpdate = (event, stats) => {},
) => {
    if (isAdmin) {
        ClassicEditor.create(
            document.querySelector(
                editorId
            ), {
                toolbar: [
                    "undo",
                    "redo",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "fontSize",
                    "fontFamily",
                    "fontColor",
                    "|",
                    "heading",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "|",
                    "insertTable",
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells"
                ],
            }
        ).then( editor => {
            if (canWordCount) {
                // update word count when editor value changes
                editor.plugins.get("WordCount").on(
                    "update",
                    onWordCountUpdate
                );
                // set initial word count
                onWordCountUpdate({}, {
                    "words": editor.plugins.get("WordCount").words
                });
            }
            callback(editor);
        }).catch(err => {
            console.error(err);
        });
    } else {
        ClassicEditor.create(
            document.querySelector(
                editorId
            ), {
                toolbar: []
            }
        ).then(editor => {
            editor.isReadOnly = true;
            console.debug(editor);
            callback(editor);
        }).catch(err => {
            console.error(err);
        });
    }
};

export const usePagination = (
    divId,
    totalPages,
    maxVisiblePages = 4,
    onPageClick,
    extraCssClasses,
) => {

    const state = {
        totalPages: totalPages,
        maxVisiblePages: maxVisiblePages,
        onPageClick: onPageClick,
        currentPage: 1,
    };

    const getVisiblePages = () => {
        return Math.min(state.maxVisiblePages, state.totalPages);
    };

    const getExtraCss = () => {
        if (extraCssClasses) return extraCssClasses.join(" ");
        return "";
    };

    const drawPagination = _.debounce(() => {
        let listElements = "";
        if (state.totalPages === 1) {
            listElements = `
                <li class="page-item ${getExtraCss()}">
                    1
                </li>
            `;
        } else {
            let pageElements = [];
            const currentPagePosition = calcCurrentPagePosition();
            for (let i = 1; i <= getVisiblePages(); ++i) {
                const caption = i + state.currentPage - currentPagePosition;
                if (state.currentPage === caption) {
                    pageElements.push(`
                        <li class="page-item ${getExtraCss()} active">
                            ${caption}
                        </li>
                    `);
                } else {
                    pageElements.push(`
                        <li class="page-item ${getExtraCss()}">
                            ${caption}
                        </li>
                    `);
                }
            }
            let prevClass = "", nextClass = "";
            if (state.currentPage === 1) {
                prevClass = " disabled";
            }
            if (state.currentPage === state.totalPages) {
                nextClass = " disabled";
            }
            listElements = `
                <li class="page-item-prev${prevClass}">
                    Previous
                </li>
                ${pageElements.join(" ")}
                <li class="page-item-next${nextClass}">
                    Next
                </li>
            `;
        }

        const container = document.querySelector(divId);
        container.innerHTML = `
            <ul id="table_pagination" class="pagination-sm pagination">
                ${listElements}
            </ul>
        `;
        return registerEvents();
    }, 200);

    const registerEvents = () => {
        document.querySelectorAll(".page-item").forEach(element => {
            element.addEventListener("click", function (event) {
                setCurrentPage(this.innerHTML);
                onPageClick(state.currentPage);
            });
        });
        if (state.currentPage > 1) {
            document.querySelectorAll(".page-item-prev").forEach(element => {
                element.addEventListener("click", function (event) {
                    setCurrentPage(state.currentPage - 1);
                    onPageClick(state.currentPage);
                })
            });
        }
        if (state.currentPage < state.totalPages) {
            document.querySelectorAll(".page-item-next").forEach(element => {
                element.addEventListener("click", function (event) {
                    setCurrentPage(state.currentPage + 1);
                    onPageClick(state.currentPage);
                })
            });
        }
        return true;
    };

    const calcCurrentPagePosition = () => {
        // currentPage is 1, go to the left
        if (state.currentPage === 1) return 1;

        // currentPage is lastPage, go to the right
        if (state.currentPage === state.totalPages) return getVisiblePages();

        // currentPage can be placed on the second to the left position (desirable)
        if (state.currentPage <= state.totalPages - getVisiblePages() + 2) return 2;

        return state.currentPage - state.totalPages + getVisiblePages();
    };

    const setTotalPages = (totalPages) => {
        let tP = parseInt(totalPages);
        if (tP < 1) {
            // minimum one page
            tP = 1;
        }
        // if total page number changes, set current page back to 1
        setCurrentPage(1);
        state.totalPages = tP;
        drawPagination();
    };

    const setCurrentPage = (currentPage) => {
        const cP = parseInt(currentPage);
        if (cP < 1) {
            throw new Error("currentPage must be greater than 0");
        }
        state.currentPage = cP;
        drawPagination();
    };

    const getCurrentPage = () => {
        return state.currentPage;
    };

    drawPagination();

    return {
        setTotalPages,
        setCurrentPage,
        getCurrentPage,
    };
};

/**
 *
 * @param tablename
 * @param paginationname
 * @param totalPageCount
 * @param ajaxUrl
 * @param columnOrderIndex
 * @param columnDefs
 * @param pageLength
 * @returns {jQuery}
 */
export const useDatatable = (
    tablename,
    paginationname,
    totalPageCount,
    ajaxUrl,
    columnOrderIndex,
    columnDefs,
    pageLength = 25,
) => {

    let lastCall = 0;

    const table = $(`#${tablename}`).DataTable({
        "pageLength": pageLength,
        "order": [[ columnOrderIndex, "asc" ]],
        "dom": '<<t>>',
        "paging": true,
        "responsive": true,
        "processing": true,
        "serverSide": true,
        "ajax": {
            url: ajaxUrl,
            type: "GET",
        },
        "columnDefs": columnDefs,
        "drawCallback": function(settings) {
            let time = Date.now();
            if (time - lastCall < 100) {
                return; /* kleiner hässlicher Hack, um eine Rekursion zu verhindern
                (das Pagination erneuern feuert aus irgendwelchen Gründen das gleiche Event erneut) */
                /* TODO ist vllt gar nicht mehr nötig, mal testen */
            }
            lastCall = time;

            /* refresh pagination */
            let num_items = this.api().page.info().recordsDisplay;

            const currentPage = pagination.getCurrentPage();
            if (num_items === 0) {
                pagination.setTotalPages(1);
                return;
            }
            const pageLen = table.page.len();
            pagination.setTotalPages(Math.ceil(num_items / pageLen));
            pagination.setCurrentPage(Math.min(currentPage, Math.ceil(num_items / pageLen)));
        }
    });

    const pagination = usePagination(
        paginationname,
        totalPageCount,
        4,
        (page) => {
            table.page(page - 1).draw("page");
        }
    );

    /* change number of items shown */
    $(".my_dataTables_length select").on("change", function() {
        table.page.len($(this).val()).draw();
    });

    // when order is changed, reset pagination
    $(`#${tablename}`).on("order.dt", () => {
        const num_items = table.page.info().recordsDisplay;
        const pageLen = table.page.len();
        pagination.setTotalPages(Math.ceil(num_items / pageLen));
    });

    /* search all columns */
    $("#free_search").on("keyup change", () => {
        table.search($("#free_search").val()).draw();
    });

    return table;
};


/**
 *
 * @param createButtonId
 * @param formId
 * @param saveButtonId
 * @param saveUrl
 * @param saveSuccessRedirect
 * @param errors expects an array of objects with the properties propertyId and containerId
 */
export const useCreateNew = (
    createButtonId,
    formId,
    saveButtonId,
    saveUrl,
    saveSuccessRedirect,
    errors,
    /*
    [
        {
            propertyId: name,
            containerId: name_container,
        }
    ]
     */
) => {

    // empty create_new form on open
    $(`#${createButtonId}`).click(function() {
        $(`#${formId}`).trigger('reset');
        $('.invalid-feedback').remove();
        $('.is-invalid').removeClass('is-invalid');

        //TODO $further_js_empty_create_new }}
    });

    // save new project on button click
    $(`#${saveButtonId}`).click(function() {
        $.ajax({
            method: "POST",
            url: saveUrl,
            data: $(`#${formId}`).serialize(),
            success: function(data) {
                window.location.href = saveSuccessRedirect;
            },
            error: function(data) {
                //console.debug(data);
                $('.invalid-feedback').remove();
                $('.is-invalid').removeClass('is-invalid');

                errors.forEach(errCase => {
                    console.error(data.responseJSON);
                    if (data.responseJSON.errors.hasOwnProperty(errCase["propertyId"])) {
                        $(`#${errCase["propertyId"]}`).addClass("is-invalid");
                        $(`#${errCase["containerId"]}`).append('<span class="invalid-feedback" ' +
                            'role="alert"><strong>' +
                            data.responseJSON.errors[errCase["propertyId"]][0] + '</strong></span>');
                    }
                });
            }
        });
    });

};

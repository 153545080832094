export const useDeleteResource = (
    confirmDialogResourceName,
    deleteUrl,
    csrf_token,
) => {
    // delete resource on trash button click
    $(document).on("click", ".trash_icon", function() {
        if (! confirm(`Are you sure you want to delete this ${confirmDialogResourceName}?`)) {
            return;
        }
        let id = $(this).data("id");
        $.ajax({
            method: "POST",
            url: deleteUrl + "/" + id,
            data: {
                "_method": "DELETE",
                "_token": csrf_token,
            },
            success: function(_data) {
                location.reload();
            },
            error: function(data) {
                console.debug(data.responseText);
            }
        });
    });
};


/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

//global.$ = global.jQuery = require("jquery");
import "select2/dist/js/select2.full.js";
import "datatables.net";
import "twbs-pagination";
import "@chenfengyuan/datepicker";
//import "select2/dist/css/select2.css";
import ClassicEditor from "@ckeditor/ckeditor5-with-word-count";
import Tagify from '@yaireo/tagify';

import "./side_navbar/util";
import "./side_navbar/menu-aim";
import "./side_navbar/main";

// custom modules

import { usePagination } from "./modules/pagination";
import { useDatatable } from "./modules/datatable";
import { goBackWithRefresh, isEmail, getBaseUrl } from "./modules/helpers";
import { useHandleTableClick } from "./modules/table-click";
import { useCreateNew } from "./modules/create-new";
import { useDeleteResource } from "./modules/delete-resource";
import { useFilters } from "./modules/filters";
import { useEditor } from "./modules/editor";
import { useTagInput } from "./modules/tags-input";

import { createApp, h } from 'vue'
import { App, plugin } from '@inertiajs/inertia-vue3'


window.ClassicEditor = ClassicEditor;
window.Tagify = Tagify;

window.usePagination = usePagination;
window.useDatatable = useDatatable;
window.goBackWithRefresh = goBackWithRefresh;
window.isEmail = isEmail;
window.getBaseUrl = getBaseUrl;
window.useHandleTableClick = useHandleTableClick;
window.useCreateNew = useCreateNew;
window.useDeleteResource = useDeleteResource;
window.useFilters = useFilters;
window.useEditor = useEditor;
window.useTagInput = useTagInput;

document.addEventListener("DOMContentLoaded", (e) => {
    const el = document.getElementById('app')
    createApp({
        render: () => h(App, {
            initialPage: JSON.parse(el.dataset.page),
            resolveComponent: name => require(`./Pages/${name}`).default,
        })
    }).use(plugin).mount(el)
});






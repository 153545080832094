<template>
    <div class="shadow rounded px-3 pt-3 pb-5 border">
        <div class="flex justify-between">
            <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">{{task.title}}</p>
        </div>
        <div class="flex mt-4 justify-between items-center">
            <span class="text-sm text-gray-600">{{task.date}}</span>
            <badge v-if="task.type" :color="badgeColor">{{task.type}}</badge>
        </div>
    </div>
</template>
<script>
import Badge from "./Badge.vue";
export default {
    components: {
        Badge
    },
    props: {
        task: {
            type: Object,
            default: () => ({})
        }
    },
    created() {
        console.debug(this.task);
    },
    computed: {
        badgeColor() {
            const mappings = {
                Design: "purple",
                "Feature Request": "teal",
                Backend: "blue",
                QA: "green",
                default: "teal"
            };
            return mappings[this.task.type] || mappings.default;
        }
    }
};
</script>

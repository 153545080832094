export const useTagInput = (
    inputId,
    placeholder,
    options = {
        editTags: 1
    }
) => {
    const tagify = new Tagify(
        document.querySelector(inputId),
        {
            placeholder: placeholder,
            ...options,
        }
    );

    return tagify;
};

<template>
    <div id="board">
        <div class="flex justify-center">
            <div class="min-h-screen flex overflow-x-scroll py-12">
                <div
                    v-for="column in columns"
                    :key="column.title"
                    class="bg-gray-100 rounded-lg px-3 py-3 column-width rounded mr-4"
                >
                    <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">{{column.title}}</p>
                    <draggable
                        v-model="column.tasks" tag="transition-group" item-key="id"
                    >

                    </draggable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import TaskCard from "./components/TaskCard.vue";
export default {
    name: "Board",
    components: {
        TaskCard,
        draggable
    },
    data() {
        return {
            columns: [
                {
                    title: "Backlog",
                    tasks: [
                        {
                            id: 1,
                            title: "Add discount code to checkout page",
                            date: "Sep 14",
                            type: "Feature Request"
                        }
                    ]
                },
                {
                    title: "In Progress",
                    tasks: [
                        {
                            id: 6,
                            title: "Design shopping cart dropdown",
                            date: "Sep 9",
                            type: "Design"
                        }
                    ]
                },
                {
                    title: "Review",
                    tasks: [
                        {
                            id: 9,
                            title: "Provide documentation on integrations",
                            date: "Sep 12"
                        }
                    ]
                },
                {
                    title: "Done",
                    tasks: [
                        {
                            id: 14,
                            title: "Add discount code to checkout page",
                            date: "Sep 14",
                            type: "Feature Request"
                        }
                    ]
                }
            ]
        };
    },
    methods: {
        change: (el) => {
            console.debug(el)
        }
    }
};
</script>

<style scoped>
.column-width {
    min-width: 320px;
    width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
    opacity: 0.5;
    color: white;
    border: 1px solid #4299e1;
}
</style>
